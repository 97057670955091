import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { login } from '../services/auth';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  Container,
  Center,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setError('Felhasználónév és jelszó megadása kötelező');
      return;
    }
    if (isLoading) {
      return; // Prevent double submission
    }
    setError('');
    setIsLoading(true);
    try {
      await authLogin(username, password);
      navigate('/');
    } catch (err) {
      setError('Érvénytelen felhasználónév vagy jelszó');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Center>
        <Box width="100%" maxWidth="400px" p={4}>
          <VStack spacing={6}>
            <Heading as="h2" size="xl">
              Bejelentkezés
            </Heading>
            {error && <Text color="red.500">{error}</Text>}
            <form onSubmit={handleSubmit} style={{ width: '100%' }} method="post">
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="username">Felhasználónév</FormLabel>
                  <Input
                    id="username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    isDisabled={isLoading}
                    autoComplete="username"
                    name="username"
                    required
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="password">Jelszó</FormLabel>
                  <InputGroup>
                    <Input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      isDisabled={isLoading}
                      autoComplete="current-password"
                      name="password"
                      required
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label={showPassword ? "Hide password" : "Show password"}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        onClick={() => setShowPassword(!showPassword)}
                        variant="ghost"
                        size="sm"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button 
                  type="submit" 
                  colorScheme="blue" 
                  width="100%" 
                  isLoading={isLoading}
                  loadingText="Bejelentkezés..."
                  disabled={isLoading}
                >
                  Bejelentkezés
                </Button>
              </VStack>
            </form>
            <Button 
              as={RouterLink} 
              to="/register" 
              colorScheme="green" 
              width="100%"
              isDisabled={isLoading}
            >
              Regisztráció
            </Button>
          </VStack>
        </Box>
      </Center>
    </Container>
  );
};

export default Login;
