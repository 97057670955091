import React, { useState, useRef } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { validateCredentials } from '../utils/validation';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  Container,
  Center,
  InputGroup,
  InputRightElement,
  IconButton,
  Checkbox,
  Link,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';

const PasswordInput = ({ id, value, onChange, label, showPassword, toggleShow }) => {
  return (
    <FormControl isRequired>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <InputGroup size="md">
        <Input
          id={id}
          pr="4.5rem"
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          autoComplete="new-password"
          name={id}
          required
        />
        <InputRightElement>
          <IconButton
            aria-label={showPassword ? "Hide password" : "Show password"}
            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
            onClick={toggleShow}
            variant="ghost"
            size="sm"
          />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const { refreshAuthStatus } = useAuth();

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const validationError = validateCredentials(username, password);
    if (validationError) {
      setError(validationError);
      return;
    }

    if (password !== confirmPassword) {
      setError('A jelszavak nem egyeznek');
      return;
    }

    if (!captchaValue) {
      setError('Kérjük, töltse ki a CAPTCHA-t');
      return;
    }

    if (!termsAccepted) {
      setError('Kérjük, fogadja el a felhasználási feltételeket');
      return;
    }

    try {
      const response = await fetch(`/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, captchaValue }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'A regisztráció sikertelen');
      }

      // Use refreshAuthStatus instead of checkSession
      await refreshAuthStatus();
      navigate('/');
    } catch (err) {
      setError(err.message);
    } finally {
      // Reset reCAPTCHA
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaValue(null);
    }
  };

  return (
    <Container>
      <Center>
        <Box width="100%" maxWidth="400px" p={4}>
          <VStack spacing={6}>
            <Heading as="h2" size="xl">
              Regisztráció
            </Heading>
            {error && <Text color="red.500">{error}</Text>}
            <form onSubmit={handleSubmit} style={{ width: '100%' }} method="post">
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="username">Felhasználónév</FormLabel>
                  <Input
                    id="username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="username"
                    name="username"
                    required
                  />
                </FormControl>
                <PasswordInput
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Jelszó"
                  showPassword={showPassword}
                  toggleShow={toggleShowPassword}
                />
                <PasswordInput
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  label="Jelszó megerősítése"
                  showPassword={showPassword}
                  toggleShow={toggleShowPassword}
                />
                {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                )}
                <Checkbox isRequired onChange={handleTermsChange}>
                  Elfogadom a <Link as={RouterLink} to="/terms-and-conditions" color="blue.500" target="_blank" rel="noopener noreferrer">felhasználási feltételeket</Link>
                </Checkbox>
                <Button type="submit" colorScheme="blue" width="100%" isDisabled={!captchaValue || !termsAccepted}>
                  Regisztráció
                </Button>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Center>
    </Container>
  );
};

export default Register;
