import React, { useState, useEffect } from 'react';
import { Box, Input, Select, Button, VStack, Text, Table, Thead, Tbody, Tr, Th, Td, useToast, HStack } from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';


const UserSearch = () => {
    const [name, setName] = useState('');
    const [restaurantId, setRestaurantId] = useState('');
    const [restaurants, setRestaurants] = useState([]);
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const { userRoles } = useAuth();
    const toast = useToast();

    useEffect(() => {
        fetchRestaurants();
    }, []);

    const fetchRestaurants = async () => {
        try {
            const response = await fetch(`/api/restaurants`);
            if (!response.ok) throw new Error('Failed to fetch restaurants');
            const data = await response.json();
            setRestaurants(data);
        } catch (error) {
            console.error('Error fetching restaurants:', error);
            toast({
                title: 'Error',
                description: 'Failed to fetch restaurants',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleSearch = async () => {
        try {
            let url = `/api/users/search?`;
            if (name) url += `name=${encodeURIComponent(name)}&`;
            if (restaurantId) url += `restaurantId=${restaurantId}`;

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Failed to search users');
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error searching users:', error);
            setUsers([]);
            toast({
                title: 'Error',
                description: 'Failed to search users',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleEdit = (user) => {
        setEditingUser(user);
    };

    const handleSave = async (user) => {
        try {
            const userToUpdate = {
                ...user,
                restaurantId: user.Restaurant ? user.Restaurant.id : null
            };
            delete userToUpdate.Restaurant;

            const response = await fetch(`/api/users/${user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(userToUpdate)
            });

            if (!response.ok) throw new Error('Failed to update user');

            const updatedUser = await response.json();
            setUsers(users.map(u => u.id === updatedUser.id ? updatedUser : u));
            setEditingUser(null);
            toast({
                title: 'Success',
                description: 'User updated successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating user:', error);
            toast({
                title: 'Error',
                description: 'Failed to update user',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (!userRoles.includes('page_admin')) {
        return <Text>You don't have permission to access this page.</Text>;
    }

    return (
        <Box>
            <VStack spacing={4} align="stretch">
                <Input 
                    placeholder="Search by name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                />
                <Select 
                    placeholder="Select restaurant" 
                    value={restaurantId} 
                    onChange={(e) => setRestaurantId(e.target.value)}
                >
                    {restaurants.map(restaurant => (
                        <option key={restaurant.id} value={restaurant.id}>
                            {restaurant.name}
                        </option>
                    ))}
                </Select>
                <Button onClick={handleSearch}>Search</Button>

                {users.length > 0 ? (
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Username</Th>
                                <Th>Roles</Th>
                                <Th>Restaurant</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users.map(user => (
                                <Tr key={user.id}>
                                    <Td>{user.username}</Td>
                                    <Td>
                                        {editingUser && editingUser.id === user.id ? (
                                            <Select 
                                                value={editingUser.roles[0]} 
                                                onChange={(e) => setEditingUser({...editingUser, roles: [e.target.value]})}
                                            >
                                                <option value="user">User</option>
                                                <option value="page_admin">Page Admin</option>
                                                <option value="restaurant_admin">Restaurant Admin</option>
                                            </Select>
                                        ) : (
                                            user.roles.join(', ')
                                        )}
                                    </Td>
                                    <Td>
                                        {editingUser && editingUser.id === user.id ? (
                                            <Select 
                                                value={editingUser.Restaurant ? editingUser.Restaurant.id : ''}
                                                onChange={(e) => {
                                                    const selectedRestaurant = restaurants.find(r => r.id === parseInt(e.target.value));
                                                    setEditingUser({
                                                        ...editingUser, 
                                                        Restaurant: selectedRestaurant 
                                                            ? {id: selectedRestaurant.id, name: selectedRestaurant.name}
                                                            : null
                                                    });
                                                }}
                                            >
                                                <option value="">No Restaurant</option>
                                                {restaurants.map(restaurant => (
                                                    <option key={restaurant.id} value={restaurant.id}>
                                                        {restaurant.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        ) : (
                                            user.Restaurant ? user.Restaurant.name : 'N/A'
                                        )}
                                    </Td>
                                    <Td>
                                        {editingUser && editingUser.id === user.id ? (
                                            <HStack>
                                                <Button onClick={() => handleSave(editingUser)}>Save</Button>
                                                <Button onClick={() => setEditingUser(null)}>Cancel</Button>
                                            </HStack>
                                        ) : (
                                            <Button onClick={() => handleEdit(user)}>Edit</Button>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Text>No users found</Text>
                )}
            </VStack>
        </Box>
    );
};

export default UserSearch;
