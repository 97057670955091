import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Select,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


const ReservationModal = ({ isOpen, onClose, restaurant, largestPossibleReservation }) => {
  const [numberOfPeople, setNumberOfPeople] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const maxPeople = Math.min(largestPossibleReservation, 4);

  const handleReservation = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/reservations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          numberOfPeople,
          restaurantId: restaurant.id,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Hiba történt a foglalás során');
      }

      toast({
        title: 'Foglalás sikeres. ',
        description: data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose();
      navigate('/my-reservations');
    } catch (error) {
      toast({
        title: 'Hiba',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Foglalás - {restaurant.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>Cím: {restaurant.address}</Text>
          <Select
            value={numberOfPeople}
            onChange={(e) => setNumberOfPeople(Number(e.target.value))}
            mb={4}
          >
            {[...Array(maxPeople)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{i + 1} fő</option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button 
            colorScheme="blue" 
            mr={3} 
            onClick={handleReservation}
            isLoading={isLoading}
          >
            Foglalás
          </Button>
          <Button variant="ghost" onClick={onClose}>Mégse</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReservationModal;
