import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
    VStack,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    Heading,
    useToast,
    Box,
    Image,
    Select,
    HStack,
    useBreakpointValue,
    Checkbox,
} from '@chakra-ui/react';

const RestaurantForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [restaurant, setRestaurant] = useState({
        name: '',
        description_hu: '',
        address: '',
        imagePath: '',
        county: '',
        maps_link: '',
        web: '',
        capacity: '',
        open: '',
        close: '',
        reservationEnabled: true,
        walkInEnabled: true,
        hasReservation: false,
        reservationMethod: '',
        isInactive: false,
        canceledReason: '',
        fullHouse: false,
    });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [counties, setCounties] = useState({});

    useEffect(() => {
        if (id) {
            fetchRestaurant();
        }
        fetchCounties();
    }, [id]);

    const fetchCounties = async () => {
        try {
            const response = await fetch(`/api/static/resources/countiesHuman.json`);
            if (!response.ok) {
                throw new Error('Failed to fetch counties');
            }
            const data = await response.json();
            setCounties(data);
        } catch (error) {
            toast({
                title: 'Hiba',
                description: 'Nem sikerült betölteni a megyéket',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const fetchRestaurant = async () => {
        try {
            const response = await fetch(`/api/restaurants/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch restaurant');
            }
            const data = await response.json();
            setRestaurant(data);
            setImagePreview(data.imagePath ? `/api/static/${data.imagePath}` : null);
        } catch (error) {
            toast({
                title: 'Hiba',
                description: 'Nem sikerült betölteni az éttermet',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setRestaurant(prev => ({...prev, [name]: type === 'checkbox' ? checked : value}));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = id ? `/api/restaurants/${id}` : `/api/restaurants`;
            const method = id ? 'PUT' : 'POST';

            const formData = new FormData();
            const restaurantData = {...restaurant};
            delete restaurantData.createdAt;
            delete restaurantData.updatedAt;
            formData.append('restaurantData', JSON.stringify(restaurantData));

            if (imageFile) {
                formData.append('image', imageFile);
            }

            const response = await fetch(url, {
                method,
                body: formData,
                credentials: 'include',
                headers: {
                    // Don't set Content-Type header, let the browser set it with the correct boundary
                },
            });

            if (!response.ok) {
                throw new Error('Failed to save restaurant');
            }

            toast({
                title: 'Siker',
                description: 'Az étterem sikeresen mentve',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            navigate('/restaurants');
        } catch (error) {
            toast({
                title: 'Hiba',
                description: 'Nem sikerült menteni az éttermet',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const isInline = useBreakpointValue({ base: false, md: true });

    return (
        <VStack spacing={4} as="form" onSubmit={handleSubmit}>
            <Heading>{id ? 'Étterem szerkesztése' : 'Új étterem hozzáadása'}</Heading>
            <FormControl isRequired>
                <FormLabel>Név</FormLabel>
                <Input name="name" value={restaurant.name} onChange={handleChange}/>
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Magyar leírás</FormLabel>
                <Textarea name="description_hu" value={restaurant.description_hu} onChange={handleChange}/>
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Cím</FormLabel>
                <Input name="address" value={restaurant.address} onChange={handleChange}/>
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Megye</FormLabel>
                <Select name="county" value={restaurant.county} onChange={handleChange}>
                    <option value="">Válassz megyét</option>
                    {Object.entries(counties).map(([key, value]) => (
                        <option key={key} value={key}>
                            {value}
                        </option>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>Google Maps Link</FormLabel>
                <Input name="maps_link" value={restaurant.maps_link} onChange={handleChange}/>
            </FormControl>
            <FormControl>
                <FormLabel>Weboldal</FormLabel>
                <Input name="web" value={restaurant.web} onChange={handleChange}/>
            </FormControl>
            <HStack width="100%" spacing={4} align="flex-start" flexDirection={isInline ? "row" : "column"}>
                <FormControl isRequired>
                    <FormLabel>Nyitás</FormLabel>
                    <Input
                        name="open"
                        type="time"
                        value={restaurant.open}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Zárás</FormLabel>
                    <Input
                        name="close"
                        type="time"
                        value={restaurant.close}
                        onChange={handleChange}
                    />
                </FormControl>
            </HStack>
            <HStack width="100%" spacing={4} align="flex-start">
                <FormControl>
                    <Checkbox
                        name="reservationEnabled"
                        isChecked={restaurant.reservationEnabled}
                        onChange={handleChange}
                    >
                    Az oldalon lehet foglalni
                    </Checkbox>
                </FormControl>
                {restaurant.reservationEnabled && (
                    <FormControl isRequired>
                        <FormLabel>Kapacitás (db fő/óra)</FormLabel>
                        <Input 
                            name="capacity" 
                            type="number" 
                            value={restaurant.capacity}
                            onChange={handleChange}
                            min="1"
                        />
                    </FormControl>
                )}
            </HStack>
            <FormControl>
                <Checkbox
                    name="walkInEnabled"
                    isChecked={restaurant.walkInEnabled}
                    onChange={handleChange}
                >
                Walk-in vendégeket fogadnak
                </Checkbox>
            </FormControl>
            <HStack width="100%" spacing={4} align="flex-start">
                <FormControl>
                    <Checkbox
                        name="hasReservation"
                        isChecked={restaurant.hasReservation}
                        onChange={handleChange}
                    >
                    Külső foglalási rendszert használnak
                    </Checkbox>
                </FormControl>
                {restaurant.hasReservation && (
                    <FormControl>
                        <FormLabel>Foglalási rendszer elérhetősége</FormLabel>
                        <Input
                            name="reservationMethod"
                            value={restaurant.reservationMethod || ''}
                            onChange={handleChange}
                            placeholder="Pl. telefonon, e-mailben, weboldalon keresztül"
                        />
                    </FormControl>
                )}
            </HStack>
            <FormControl>
                <Checkbox
                    name="isInactive"
                    isChecked={restaurant.isInactive}
                    onChange={handleChange}
                >
                Inaktív étterem
                </Checkbox>
            </FormControl>
            <FormControl>
                <Checkbox
                    name="fullHouse"
                    isChecked={restaurant.fullHouse}
                    onChange={handleChange}
                >
                    Teltház
                </Checkbox>
            </FormControl>
            {(restaurant.isInactive || restaurant.fullHouse) && (
                <FormControl>
                    <FormLabel>Inaktiválás oka/Teltház megjegyzés(nem kötelező)</FormLabel>
                    <Input
                        name="canceledReason"
                        value={restaurant.canceledReason || ''}
                        onChange={handleChange}
                        placeholder="Pl. Ideiglenesen zárva, Véglegesen bezárt, Teltház"
                    />
                </FormControl>
            )}
            <FormControl>
                <FormLabel>Kép</FormLabel>
                <Input type="file" accept="image/*" onChange={handleImageChange}/>
            </FormControl>
            {imagePreview ? (
                <Box>
                    <Image 
                        src={imagePreview} 
                        alt="Restaurant preview" 
                        maxWidth="200px"
                        onError={() => setImagePreview(null)}
                    />
                </Box>
            ) : (
                <Box>No image available</Box>
            )}
            <Button type="submit" colorScheme="blue">
                Mentés
            </Button>
        </VStack>
    );
};

export default RestaurantForm;
