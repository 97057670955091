import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    useToast,
    Button,
    Box,
    useBreakpointValue,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';
import { logout as logoutService } from '../services/auth';

const NavItems = ({ isLoggedIn, isAdmin, userRoles, handleLogout }) => {
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
        <>
            <Button 
                as={RouterLink} 
                to="/" 
                variant="ghost" 
                mx={2}
                colorScheme={isActive('/') ? 'blue' : 'gray'}
            >
                Főoldal
            </Button>
            <Button 
                as={RouterLink} 
                to="/restaurants" 
                variant="ghost" 
                mx={2}
                colorScheme={isActive('/restaurants') ? 'blue' : 'gray'}
            >
                Éttermek
            </Button>
            <Button 
                as={RouterLink} 
                to="/what-is-this" 
                variant="ghost" 
                mx={2}
                colorScheme={isActive('/what-is-this') ? 'blue' : 'gray'}
            >
                Mi is ez?
            </Button>
            {!isLoggedIn ? (
                <Button 
                    as={RouterLink} 
                    to="/login" 
                    variant="ghost" 
                    mx={2}
                    colorScheme={isActive('/login') ? 'blue' : 'gray'}
                >
                    Bejelentkezés/Regisztráció
                </Button>
            ) : (
                <>
                    <Button 
                        as={RouterLink} 
                        to="/my-reservations" 
                        variant="ghost" 
                        mx={2}
                        colorScheme={isActive('/my-reservations') ? 'blue' : 'gray'}
                    >
                        {isAdmin ? 'Foglalások' : 'Fogalásom'}
                    </Button>
                    {userRoles.includes('page_admin') && (
                        <Button 
                            as={RouterLink} 
                            to="/user-search" 
                            variant="ghost" 
                            mx={2}
                            colorScheme={isActive('/user-search') ? 'blue' : 'gray'}
                        >
                            Felhasználók keresése
                        </Button>
                    )}
                    <Button onClick={handleLogout} variant="ghost" mx={2}>
                        Kijelentkezés
                    </Button>
                </>
            )}
        </>
    );
};

const NavBar = () => {
    const { isLoggedIn, logout, refreshAuthStatus, userRoles } = useAuth();
    const navigate = useNavigate();
    const toast = useToast();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleLogout = async () => {
        try {
            await logoutService();
            logout();
            await refreshAuthStatus();
            toast({
                title: 'Sikeresen kijelentkezett',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            navigate('/');
        } catch (error) {
            toast({
                title: 'Kijelentkezés sikertelen',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const isAdmin = userRoles.includes('page_admin') || userRoles.includes('restaurant_admin');

    return (
        <Flex 
            as="nav" 
            bg="navbar" 
            py={4} 
            px={6} 
            width="100%" 
            justifyContent={isMobile ? "flex-end" : "center"} 
            alignItems="center"
        >
            {isMobile ? (
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Opciók'
                        icon={<HamburgerIcon />}
                        variant="outline"
                    />
                    <MenuList>
                        <MenuItem as={RouterLink} to="/">
                            Főoldal
                        </MenuItem>
                        <MenuItem as={RouterLink} to="/restaurants">
                            Éttermek
                        </MenuItem>
                        <MenuItem as={RouterLink} to="/what-is-this">
                            Mi is ez?
                        </MenuItem>
                        {!isLoggedIn ? (
                            <MenuItem as={RouterLink} to="/login">
                                Bejelentkezés/Regisztráció
                            </MenuItem>
                        ) : (
                            <>
                                <MenuItem as={RouterLink} to="/my-reservations">
                                    {isAdmin ? 'Foglalások' : 'Fogalásom'}
                                </MenuItem>
                                {userRoles.includes('page_admin') && (
                                    <MenuItem as={RouterLink} to="/user-search">
                                        Felhasználók keresése
                                    </MenuItem>
                                )}
                                <MenuItem onClick={handleLogout}>
                                    Kijelentkezés
                                </MenuItem>
                            </>
                        )}
                    </MenuList>
                </Menu>
            ) : (
                <Box>
                    <NavItems
                        isLoggedIn={isLoggedIn}
                        isAdmin={isAdmin}
                        userRoles={userRoles}
                        handleLogout={handleLogout}
                    />
                </Box>
            )}
        </Flex>
    );
};

export default NavBar;
