import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Bungee';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/api/static/resources/Bungee/Bungee-Regular.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/api/static/resources/Quicksand/Quicksand-VariableFont_wght.ttf') format('truetype');
      }
    `}
  />
)

export default Fonts
