import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem, Container } from '@chakra-ui/react';

const CookiePolicy = () => {
  return (
    <Container maxW="container.xl" py={8}>
      <Box>
        <Heading as="h2" size="2xl" textAlign="left">Cookie Szabályzat</Heading>
        <Text mb={4}>
          Ez a weboldal 1 cookie-t használ az alapvető funkcionalitás biztosítása érdekében. A weboldal használatával Ön elfogadja a cookie használatát.
        </Text>
        <Heading as="h2" size="lg" mb={4}>Az általunk használt cookie</Heading>
        <UnorderedList spacing={2}>
          <ListItem>
            <Text><strong>token</strong> Ez a cookie, elengedhetetlen az oldal megfelelő működéséhez és a felhasználói hitelesítéshez.</Text>
          </ListItem>
        </UnorderedList>
        <Heading as="h2" size="lg" mt={6} mb={4}>Hogyan szabályozhatja a cookie-kat</Heading>
        <Text mb={4}>
          A legtöbb webböngésző lehetővé teszi a cookie-k szabályozását a beállításokon keresztül. Azonban ha korlátozza a weboldalak képességét a cookie-k beállítására, az ronthatja az általános felhasználói élményt, mivel ez befolyásolja weboldalunk megfelelő működését.
        </Text>
        <Text mb={4}>
          Ha többet szeretne megtudni arról, hogyan kezelheti a cookie-kat a webböngészőjében, látogasson el az alábbi oldalakra:
        </Text>
        <UnorderedList spacing={2}>
          <ListItem>
            <Text>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=hu" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=hu</a></Text>
          </ListItem>
          <ListItem>
            <Text>Firefox: <a href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn</a></Text>
          </ListItem>
          <ListItem>
            <Text>Safari: <a href="https://support.apple.com/hu-hu/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/hu-hu/guide/safari/sfri11471/mac</a></Text>
          </ListItem>
        </UnorderedList>
        <Text mt={4}>
          Kérjük, vegye figyelembe, hogy a cookie-k letiltása befolyásolja ennek és sok más weboldalnak a funkcionalitását, amelyeket meglátogat. Ezért azt javasoljuk, hogy ne tiltsa le a cookie-kat.
        </Text>
      </Box>
    </Container>
  );
};

export default CookiePolicy;
