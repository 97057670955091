import React, {useState, useEffect} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {
    VStack,
    Heading,
    Text,
    Center,
    Spinner,
    Card,
    CardBody,
    CardHeader,
    Image,
    Box,
    Link,
    Button,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    SimpleGrid,
    useBreakpointValue,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {useAuth} from '../contexts/AuthContext';

const reservationStatusStrings = {
    walkInOnly: "Mindenkit várnak foglalás nélkül",
    walkInAndReservation: "Mindenkit várnak és van foglalási lehetőség",
    reservationOnly: "Csak a foglalással rendelkező vendégeket fogadják"
};

const getReservationStatus = (reservationEnabled, hasReservation, walkInEnabled) => {
    if (walkInEnabled) {
        if (!reservationEnabled && !hasReservation) return reservationStatusStrings.walkInOnly;
        return reservationStatusStrings.walkInAndReservation;
    }
    return reservationStatusStrings.reservationOnly;
};

const Restaurants = () => {
    const columns = useBreakpointValue({base: 1, md: 2, lg: 2});
    const [restaurants, setRestaurants] = useState([]);
    const [counties, setCounties] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [filter, setFilter] = useState('');
    const [openAccordions, setOpenAccordions] = useState([]);
    const {userRoles, refreshAuthStatus} = useAuth();
    const navigate = useNavigate();

    const content = {
        title: "Éttermek",
        error: "Nem sikerült betölteni az éttermeket",
        noRestaurants: "Nem található étterem",
        addRestaurant: "Új étterem hozzáadása",
        edit: "Szerkesztés",
        filterPlaceholder: "Étterem keresése...",
    };

    const isPageAdmin = userRoles.includes('page_admin');

    const handleAddRestaurant = () => {
        navigate('/restaurants/new');
    };

    const handleFilterChange = (event) => {
        const newFilter = event.target.value;
        setFilter(newFilter);
        updateAccordions(newFilter);
    };

    const clearFilter = () => {
        setFilter('');
        setOpenAccordions([]);
        // Reset accordions to their default state
        updateAccordions('');
    };

    const updateAccordions = (newFilter) => {
        if (newFilter.length >= 2) {
            setOpenAccordions([...Array(Object.keys(sortedGroupedRestaurants).length).keys()]);
        } else if (newFilter.length === 0) {
            // Close all accordions when filter is empty
            setOpenAccordions([]);
        } else {
            // Keep currently open accordions open
            setOpenAccordions((prev) => prev.filter((index) => openAccordions.includes(index)));
        }
    };

    useEffect(() => {
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await refreshAuthStatus();
                const [restaurantsResponse, countiesResponse] = await Promise.all([
                    fetch(`/api/restaurants`),
                    fetch(`/api/static/resources/countiesHuman.json`)
                ]);

                if (!restaurantsResponse.ok || !countiesResponse.ok) {
                    throw new Error('Failed to fetch data');
                }

                const restaurants = await restaurantsResponse.json();
                const counties = await countiesResponse.json();

                setRestaurants(restaurants);
                setCounties(counties);
            } catch (err) {
                setError(content.error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [refreshAuthStatus]);

    const groupedRestaurants = restaurants.reduce((acc, restaurant) => {
        const countyName = counties[restaurant.county] || restaurant.county;
        if (!acc[countyName]) {
            acc[countyName] = [];
        }
        acc[countyName].push(restaurant);
        return acc;
    }, {});

    // Sort restaurants within each county
    Object.keys(groupedRestaurants).forEach(county => {
        groupedRestaurants[county].sort((a, b) => a.name.localeCompare(b.name));
    });

    // Sort counties
    const sortedGroupedRestaurants = Object.keys(groupedRestaurants)
        .sort()
        .reduce((acc, county) => {
            acc[county] = groupedRestaurants[county];
            return acc;
        }, {});

    if (loading) {
        return (
            <Center minHeight="100vh">
                <Spinner size="xl"/>
            </Center>
        );
    }

    const RestaurantCard = ({restaurant}) => (
        <Card minHeight="500px" height="auto" transition="transform 0.2s" _hover={{transform: 'scale(1.05)'}}>
            <CardHeader minHeight="60px" height="auto">
                <Heading size="md" fontWeight="bold" fontFamily="Quicksand">{restaurant.name}</Heading>
            </CardHeader>
            <CardBody overflow="hidden">
                <Link as={RouterLink} to={`/restaurants/${restaurant.id}`} _hover={{textDecoration: 'none'}}>
                    <Box mb={4}>
                        {restaurant.imagePath ? (
                            <Image
                                src={`/api/static/${restaurant.imagePath}`}
                                alt={restaurant.name}
                                borderRadius="md"
                                objectFit="cover"
                                width="100%"
                                height="200px"
                            />
                        ) : (
                            <Box
                                width="100%"
                                height="200px"
                                bg="gray.200"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="md"
                            >
                                <Text>Nincs elérhető kép</Text>
                            </Box>
                        )}
                    </Box>
                    <Text noOfLines={3}>
                        {restaurant.description_hu.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br/>
                            </React.Fragment>
                        ))}
                    </Text>
                    <Text mt={2} noOfLines={1}>
                        <strong>Cím:</strong>{' '}
                        {restaurant.maps_link ? (
                            <Link href={restaurant.maps_link} isExternal color="blue.500" onClick={(e) => e.stopPropagation()}>
                                {restaurant.address}
                            </Link>
                        ) : (
                            restaurant.address
                        )}
                    </Text>
                    {restaurant.web && restaurant.web.trim() !== '' && (
                        <Text mt={2}>
                            <strong>Weboldal:</strong>{' '}
                            <Link
                                href={restaurant.web}
                                isExternal
                                color="blue.500"
                                onClick={(e) => e.stopPropagation()}
                                wordBreak="break-all"
                            >
                                {restaurant.web}
                            </Link>
                        </Text>
                    )}
                    {!restaurant.fullHouse && (
                        <Text mt={4} fontWeight="bold" color="gray.600">
                            {getReservationStatus(restaurant.reservationEnabled, restaurant.hasReservation, restaurant.walkInEnabled)}
                        </Text>
                    )}
                    <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                        <Flex alignItems="center">
                            {restaurant.fullHouse && (
                                <>
                                    <Text fontSize="md" fontWeight="extrabold" color="orange.500" mr={2}>
                                        Megtelt
                                    </Text>
                                    {restaurant.canceledReason && restaurant.canceledReason.trim() !== '' && (
                                        <Text fontSize="md" color="blue.500" fontWeight="medium">
                                            {restaurant.canceledReason}
                                        </Text>
                                    )}
                                </>
                            )}
                            {!restaurant.fullHouse && (restaurant.reservationEnabled || restaurant.hasReservation) && (
                                <Text fontSize="sm" fontStyle="italic" color="blue.500">
                                    Foglaláshoz katt az étteremre
                                </Text>
                            )}
                        </Flex>
                        {isPageAdmin && (
                            <Button
                                as={RouterLink}
                                to={`/restaurants/edit/${restaurant.id}`}
                                size="sm"
                                colorScheme="blue"
                            >
                                {content.edit}
                            </Button>
                        )}
                    </Flex>
                </Link>

            </CardBody>
        </Card>
    );

    return (
        <Box>
            <Flex
                direction={["column", "row"]}
                align={["stretch", "center"]}
                justify="space-between"
                mb={6}
            >
                <Heading as="h2" size="2xl" textAlign="left" mb={[4, 0]}>
                    {content.title}
                </Heading>
                <InputGroup width={["100%", "300px"]}>
                    <Input
                        placeholder={content.filterPlaceholder}
                        value={filter}
                        onChange={handleFilterChange}
                        variant='flushed'
                        bg="transparent"
                        _placeholder={{color: 'gray.800'}}
                    />
                    {filter && (
                        <InputRightElement>
                            <Button size="sm" onClick={clearFilter} variant="ghost">
                                <CloseIcon />
                            </Button>
                        </InputRightElement>
                    )}
                </InputGroup>
            </Flex>
            <VStack spacing={10} align="stretch">
                {isPageAdmin && (
                    <Button colorScheme="blue" onClick={handleAddRestaurant}>
                        {content.addRestaurant}
                    </Button>
                )}
                {error && <Text color="red.500" textAlign="center">{error}</Text>}
                {!error && restaurants.length === 0 && (
                    <Text textAlign="center">{content.noRestaurants}</Text>
                )}
                <Accordion
                    allowMultiple
                    defaultIndex={[]}
                    index={openAccordions}
                    onChange={(expandedIndex) => setOpenAccordions(expandedIndex)}
                >
                    {Object.entries(sortedGroupedRestaurants).map(([county, countyRestaurants]) => {
                        const filteredRestaurants = countyRestaurants.filter(restaurant =>
                            restaurant.name.toLowerCase().includes(filter.toLowerCase())
                        );
                        if (filteredRestaurants.length === 0) return null;
                        return (
                            <AccordionItem key={county}>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Heading size="md" fontWeight="bold" fontFamily="Quicksand">{county}</Heading>
                                        </Box>
                                        <AccordionIcon/>
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <SimpleGrid columns={columns} spacing={4}>
                                        {filteredRestaurants.map((restaurant) => (
                                            <RestaurantCard key={restaurant.id} restaurant={restaurant}/>
                                        ))}
                                    </SimpleGrid>
                                </AccordionPanel>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            </VStack>
        </Box>
    );
};

export default Restaurants;
