import React from 'react';
import {
    Box,
    Heading,
    Text,
    VStack,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';

const TermsAndConditions = () => {
    return (
        <Box>
            <Heading as="h1" size="xl" mb={6}>Felhasználási Feltételek</Heading>
            <VStack spacing={6} align="stretch">

                <Text>
                    Üdvözöljük a Nápolyi Pizzériák Éjszakája weboldalán! Az oldal használatával Ön elfogadja az alábbi feltételeket:
                </Text>

                <Box>
                    <Heading as="h2" size="lg" mb={2}>1. Szolgáltatás használata</Heading>
                    <Text>
                        A Nápolyi Pizzériák Éjszakája weboldal lehetőséget biztosít a rendezvényen résztvevő pizzériák megismerésére és esetenként a rendezvény ideje alatti időpontfoglalásra. Az
                        oldalon való időpontfoglaláshoz regisztráció szükséges
                    </Text>
                </Box>

                <Box>
                    <Heading as="h2" size="lg" mb={2}>2. Felhasználói felelősség</Heading>
                    <Text>
                        A felhasználók felelősek a saját fiókjuk biztonságáért és az azon keresztül végzett tevékenységekért. Kérjük hogy regisztráció folyamán se felhasznlónevükben, se jelszavukban
                        semilyen személyes információt ne használjanak
                    </Text>
                </Box>

                <Box>
                    <Heading as="h2" size="lg" mb={2}>3. Foglalások</Heading>

                    <Text> Az oldalon keresztül intézett foglalások adatait az oldal üzemltetője megváltoztathatja, ezért, a felhasználó felelőssége annak meglétét és tartalmát
                        ellenőriznie.</Text>

                </Box>

                <Box>
                    <Heading as="h2" size="lg" mb={2}>4. Adatvédelem</Heading>
                    <Text>
                        Az esetlegesen az oldalon bármilyen formában tárolt személyes adatok nyilvánossá válása esetén az oldal üzemeltetője semmilyen felelősséget nem vállal.
                    </Text>
                </Box>

                <Box>
                    <Heading as="h2" size="lg" mb={2}>5. Módosítások</Heading>
                    <Text>
                        Fenntartjuk a jogot ezen feltételek módosítására. A változásokról értesítjük felhasználóinkat az oldalon keresztül.
                    </Text>
                </Box>

                <Text>
                    A Nápolyi Pizzériák Éjszakája használatával Ön elfogadja ezeket a feltételeket. Ha nem ért egyet a feltételekkel, kérjük, ne használja szolgáltatásunkat!
                </Text>
            </VStack>
        </Box>
    );
};

export default TermsAndConditions;
