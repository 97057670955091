import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
    VStack,
    Heading,
    Text,
    Container,
    Image,
    Box,
    Spinner,
    Center,
    Grid,
    GridItem,
    Badge,
    Link,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Button,
    Flex,
    useDisclosure,
    Progress,
    useToast,
    Icon,
} from '@chakra-ui/react';
import {CopyIcon} from '@chakra-ui/icons';
import {useAuth} from '../contexts/AuthContext';
import ReservationModal from './ReservationModal';

const RestaurantDetail = () => {
    const [restaurant, setRestaurant] = useState(null);
    const [reservationDetails, setReservationDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const {id} = useParams();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isLoggedIn, userRoles} = useAuth();
    const navigate = useNavigate();
    const toast = useToast();

    const canReserve = !isLoggedIn || userRoles.includes('user');

    const copyToClipboard = (text) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(text).then(() => {
                toast({
                    title: "Másolva!",
                    description: "A foglalási információ a vágólapra másolva.",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            }).catch(err => {
                console.error('Failed to copy text: ', err);
                fallbackCopyTextToClipboard(text);
            });
        } else {
            fallbackCopyTextToClipboard(text);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            toast({
                title: successful ? "Másolva!" : "Hiba történt",
                description: successful ? "A foglalási információ a vágólapra másolva." : "Nem sikerült másolni a szöveget.",
                status: successful ? "success" : "error",
                duration: 2000,
                isClosable: true,
            });
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            toast({
                title: "Hiba történt",
                description: "Nem sikerült másolni a szöveget.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
        document.body.removeChild(textArea);
    };

    const isReservationPeriodOpen = () => {
        const cutoffDate = new Date('2024-09-19T12:00:00+02:00');
        const currentDate = new Date();
        return currentDate <= cutoffDate;
    };

    const handleReservationClick = () => {
        if (isLoggedIn) {
            onOpen();
        } else {
            navigate('/login');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [restaurantResponse, reservationDetailsResponse] = await Promise.all([
                    fetch(`/api/restaurants/${id}`),
                    fetch(`/api/reservations/reservation_details/${id}`)
                ]);

                if (!restaurantResponse.ok || !reservationDetailsResponse.ok) {
                    throw new Error('Failed to fetch data');
                }

                const restaurantData = await restaurantResponse.json();
                const reservationDetailsData = await reservationDetailsResponse.json();

                setRestaurant(restaurantData);
                setReservationDetails(reservationDetailsData);
            } catch (err) {
                setError('Nem sikerült betölteni az étterem adatait')
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return (
            <Center minHeight="100vh">
                <Spinner size="xl"/>
            </Center>
        );
    }

    if (error) {
        return (
            <Container maxW="container.xl" py={8}>
                <Text color="red.500" textAlign="center">{error}</Text>
            </Container>
        );
    }

    if (!restaurant) {
        return (
            <Container maxW="container.xl" py={8}>
                <Text textAlign="center">Étterem nem található</Text>
            </Container>
        );
    }

    return (
        <Box>
            <Heading as="h2" size="2xl" textAlign="left" marginBottom="6">
                {restaurant.name}
            </Heading>
            <VStack spacing={10} align="stretch">
                <Grid templateColumns={{base: "1fr", md: "1fr 1fr"}} gap={8}>
                    <GridItem>
                        {restaurant.imagePath ? (
                            <Image
                                src={`/api/static/${restaurant.imagePath}`}
                                alt={restaurant.name}
                                borderRadius="md"
                                objectFit="cover"
                                width="100%"
                                height="400px"
                            />
                        ) : (
                            <Box
                                width="100%"
                                height="400px"
                                bg="gray.200"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="md"
                            >
                                <Text>Nincs elérhető kép</Text>
                            </Box>
                        )}
                    </GridItem>
                    <GridItem>
                        <VStack align="stretch" spacing={4}>
                            {restaurant.description_hu.split('\n').map((line, index) => (
                                <Text key={index}>
                                    {line}
                                </Text>
                            ))}
                            <Box>
                                <Text fontSize="lg">
                                    <strong>Cím:</strong>{' '}
                                    {restaurant.maps_link ? (
                                        <Link href={restaurant.maps_link} isExternal color="blue.500">
                                            {restaurant.address}
                                        </Link>
                                    ) : (
                                        restaurant.address
                                    )}
                                </Text>
                            </Box>
                            {restaurant.web && restaurant.web.trim() !== '' && (
                                <Box>
                                    <Text fontSize="lg">
                                        <strong>Weboldal:</strong>{' '}
                                        <Link
                                            href={restaurant.web}
                                            isExternal
                                            color="blue.500"
                                            wordBreak="break-all"
                                        >
                                            {restaurant.web}
                                        </Link>
                                    </Text>
                                </Box>
                            )}
                            <Box>
                                <Text fontSize="lg" fontWeight="bold" mb={2}>Rendelés felvétel:</Text>
                                <Text>{`Kezdése: ${restaurant.open}`}</Text>
                                <Text>{`Vége: ${restaurant.close}`}</Text>
                            </Box>
                            {restaurant.fullHouse && (
                                <>
                                    <Flex justifyContent="flex-start" mt={2}>
                                        <Badge bg="red.500" color="white" fontSize="xl" p={2} borderRadius="md">
                                            Megtelt
                                        </Badge>
                                    </Flex>
                                    {restaurant.canceledReason && restaurant.canceledReason.trim() !== '' && (
                                        <Box mt={2} bg="red.500" p={2} borderRadius="md">
                                            <Text fontSize="lg" fontWeight="bold" color="white">
                                                {restaurant.canceledReason}
                                            </Text>
                                        </Box>
                                    )}
                                </>
                            )}
                            {restaurant.walkInEnabled && !restaurant.fullHouse && (
                                <Box>
                                    <Text fontSize="lg">
                                        <strong>
                                            {restaurant.hasReservation || restaurant.reservationEnabled
                                                ? "Az étteremben foglaláson felül is várnak vendégeket"
                                                : "Az étteremben mindenkit várnak foglalás nélkül"}
                                        </strong>
                                    </Text>
                                </Box>
                            )}
                        </VStack>
                    </GridItem>
                </Grid>
                {restaurant.reservationEnabled && !restaurant.fullHouse && (
                    <Box mt={8}>
                        <Box borderBottom="1px" borderColor="gray.200" mb={4}></Box>
                        <Flex direction={{base: "column", lg: "row"}} justify="space-between" align={{base: "stretch", lg: "flex-start"}}>
                            <Box flex="1">
                                {reservationDetails && (
                                    <StatGroup>
                                        <Stat>
                                            <StatLabel fontSize="lg">Elérhető legnagyobb foglalás (fő)</StatLabel>
                                            <StatNumber>{reservationDetails.largestPossibleReservation}</StatNumber>
                                        </Stat>
                                        <Stat>
                                            <StatLabel fontSize="lg">Foglalt helyek aránya:</StatLabel>
                                            <Box>
                                                <Progress
                                                    value={(reservationDetails.occupancy * 100).toFixed(0)}
                                                    colorScheme="blue"
                                                    size="lg"
                                                    borderRadius="sm"
                                                />
                                                <Text mt={2} fontWeight="bold" textAlign="center">
                                                    {(reservationDetails.occupancy * 100).toFixed(0)}%
                                                </Text>
                                            </Box>
                                        </Stat>
                                    </StatGroup>
                                )}
                            </Box>
                            {canReserve && isReservationPeriodOpen() && restaurant.reservationEnabled && reservationDetails && reservationDetails.largestPossibleReservation > 0 && (
                                <Box mt={{base: 4, lg: 0}} ml={{lg: 4}}>
                                    <Button
                                        size="lg"
                                        colorScheme="green"
                                        onClick={handleReservationClick}
                                    >
                                        Foglalás
                                    </Button>
                                </Box>
                            )}
                            {!isReservationPeriodOpen() && (
                                <Box mt={{base: 4, lg: 0}} ml={{lg: 4}}>
                                    <Badge colorScheme="yellow">A foglalási időszak az oldalon lezárult</Badge>
                                </Box>
                            )}
                        </Flex>
                    </Box>
                )}

            </VStack>
            {restaurant && reservationDetails && !restaurant.fullHouse && (
                <ReservationModal
                    isOpen={isOpen}
                    onClose={onClose}
                    restaurant={restaurant}
                    largestPossibleReservation={reservationDetails.largestPossibleReservation}
                />
            )}
            {restaurant.hasReservation && !restaurant.fullHouse && (
                <Box mt={8}>
                    <Box borderBottom="1px" borderColor="gray.200" mb={4}></Box>
                    <Box mt={4} p={4}>
                        {restaurant.reservationEnabled ? (
                            <Text fontSize="lg" fontWeight="bold" mb={2}>Foglalni itt tudtok, akkor is ha nálunk már nem lehet:</Text>
                        ) : <Text fontSize="lg" fontWeight="bold" mb={2}>Foglalás:</Text>}
                        {restaurant.reservationMethod.split(',').map((method, index) => {
                            const trimmedMethod = method.trim();
                            const displayMethod = /^\d+$/.test(trimmedMethod) ? `+${trimmedMethod}` : trimmedMethod;
                            const isUrl = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(displayMethod);

                            if (isUrl) {
                                return (
                                    <Link
                                        key={index}
                                        href={displayMethod.startsWith('http') ? displayMethod : `http://${displayMethod}`}
                                        isExternal
                                        color="blue.500"
                                        fontSize="xl"
                                        mb={2}
                                        display="block"
                                    >
                                        {displayMethod}
                                    </Link>
                                );
                            } else {
                                return (
                                    <Flex
                                        key={index}
                                        align="center"
                                        cursor="pointer"
                                        onClick={() => copyToClipboard(displayMethod)}
                                        _hover={{textDecoration: 'underline'}}
                                        mb={2}
                                    >
                                        <Text mr={2} fontSize="xl">{displayMethod}</Text>
                                        <Icon as={CopyIcon} w={5} h={5} color="gray.500"/>
                                    </Flex>
                                );
                            }
                        })}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default RestaurantDetail;
