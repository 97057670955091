import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
    fonts: {
        heading: 'Bungee, sans-serif',
        body: 'Quicksand, sans-serif',
    },
    colors: {
        navbar: '#f5c329',
        footer: '#f5c329',
    },
    components: {
        Box: {
            variants: {
                navbar: {
                    bg: 'navbar',
                },
                footer: {
                    bg: 'footer',
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    bg: '#fff',
                },
            },
            variants: {
                outline: {
                    field: {
                        bg: '#fff',
                    },
                },
                filled: {
                    field: {
                        bg: '#fff',
                    },
                },
                flushed: {
                    field: {
                        bg: '#fff',
                    },
                },
            },
        },
        Textarea: {
            baseStyle: {
                bg: '#fff',
            },
            variants: {
                outline: {
                    bg: '#fff',
                },
                filled: {
                    bg: '#fff',
                },
                flushed: {
                    bg: '#fff',
                },
            },
        },
        Select: {
            baseStyle: {
                field: {
                    bg: '#fff',
                },
            },
            variants: {
                outline: {
                    field: {
                        bg: '#fff',
                    },
                },
                filled: {
                    field: {
                        bg: '#fff',
                    },
                },
                flushed: {
                    field: {
                        bg: '#fff',
                    },
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    bg: '#fff',
                },
            },
        },
        FormControl: {
            baseStyle: {
                bg: '#fff',
            },
        },
    },
    styles: {
        global: {
            body: {
                bg: '#c38383',
            },
            'input, textarea, select': {
                bg: '#fff',
            },
        },
    },
});

export default theme;
