export const validateCredentials = (username, password) => {
  if (username.length < 5) {
    return 'A felhasználónévnek legalább 5 karakter hosszúnak kell lennie';
  }

  if (password.length < 6) {
    return 'A jelszónak legalább 6 karakter hosszúnak kell lennie';
  }

  if (!/[^a-zA-Z]/.test(password)) {
    return 'A jelszónak tartalmaznia kell legalább egy olyan karaktert ami nem betű';
  }

  return null;
};
