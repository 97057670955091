import React from 'react';
import {
    Box,
    VStack,
    Heading,
    Image,
    Text,
    Flex,
    Link,
    useBreakpointValue,
} from '@chakra-ui/react';

const IndexPage = () => {
    const isMobile = useBreakpointValue({base: true, md: false});

    return (
        <Box>
            <Heading as="h2" size="2xl" textAlign="center" marginBottom="6">
                Nápolyi Pizzériák Éjszakája
            </Heading>
            <VStack spacing={10} align="center">
                <Flex
                    padding="0 20px"
                    alignItems="flex-start"
                    direction={isMobile ? "column" : "row"}
                >
                    <Image
                        src="api/static/resources/logobig.png"
                        alt="Pizza Application Logo"
                        maxWidth={isMobile ? "100%" : "200px"}
                        objectFit="contain"
                        marginRight={isMobile ? "0" : "20px"}
                        marginBottom={isMobile ? "20px" : "0"}
                        alignSelf={isMobile ? "center" : "flex-start"}
                    />
                    <VStack spacing={4} align="stretch">
                        <Text textAlign="justify" fontSize="2xl">
                            Ígértük, hogy hagyomány lesz, így 2024 szeptember 19-én ismét együtt pizzázunk országszerte. Zalától Baranyáig, 18:00-23:00 óráig, a kijelölt éttermekben/pizzériákban,
                            ünnepi, 50%-os áron.
                        </Text>
                        <Text textAlign="justify" fontSize="2xl">
                            A tavalyi nagy érdeklődésre való tekintettel ezúttal előjegyzési rendszerrel tettük gördülékenyebbé, hogy biztosítani tudjátok pizzátokat a kinézett helyen.
                        </Text>
                    </VStack>
                </Flex>
            </VStack>
            <VStack>
                <Flex
                    padding="20px 20px"
                    alignItems="flex-start"
                    direction={isMobile ? "column" : "row"}
                >
                    <VStack spacing={4} align="stretch">
                        <Text textAlign="justify" fontSize="2xl">1. Nézd ki a szimpatikus pizzériát!</Text>
                        <Text textAlign="justify" fontSize="2xl">2. Az adatlapján ellenőrizd, hogy lehet-e foglalni hozzájuk!</Text>
                        <Text textAlign="justify" fontSize="2xl">3. Regisztrálj (ha szükséges) és az ott feltüntetett módon foglald le magadnak/magatoknak az ünnepi pizzád!</Text>
                        <Text textAlign="justify" fontSize="2xl">4. Pontosan jelenj meg a foglalt időpontodra, mert kedvenc pizzériád számol veled!</Text>
                        <Text textAlign="justify" fontSize="2xl" marginTop="8">
                            Ci vediamo!
                        </Text>
                    </VStack>
                </Flex>
            </VStack>
            <Box textAlign="right" paddingRight="20px" marginTop="4">
                <Text fontSize="xl">
                    Megtaláltok a{' '}
                    <Link
                        href="https://www.facebook.com/profile.php?id=61550673554463"
                        isExternal
                        color="blue.800"
                        textDecoration="underline"
                    >
                        Facebookon
                    </Link>
                </Text>
            </Box>
        </Box>
    );
};

export default IndexPage;
