import React from 'react';
import {Box, Heading, Text, VStack} from '@chakra-ui/react';

const WhatIsThis = () => {
    return (
        <Box>
            <Heading as="h2" size="2xl" textAlign="left" marginBottom="6">Mi is ez?</Heading>
            <VStack spacing={10} align="center">
                <Text textAlign="justify" fontSize="2xl">
                    Vezúv, sikátorok, Diego Maradona. Vespák, fantasztikus pizzák és életöröm, ami azonnal átragad a nyitott szívvel érkező kíváncsi látogatóra. Nápoly maga az élet, minden
                    szépségével és nehézségével, szűnni nem akaró kitartásával. A város védőszentje a vértanúhalált halt néhai püspök San Gennaro (Szent Januáriusz), kinek fiolában őrzött vére
                    először 313-ban várt újra folyékonnyá a Santa Maria Assunta-katedrálisban.
                </Text>
                <Text textAlign="justify" fontSize="2xl">
                    Minden év szeptember 19-e óriási vallási és persze világi fieszta Nápolyban, amikor ezt a vérmisztériumot ünneplik és azért fohászkodnak, hogy a következő év is szerencsét
                    hozzon Campania fővárosának.
                </Text>
                <Text textAlign="justify" fontSize="2xl">
                    Ünnepelnek az utcán, a téren, zenével, dallal, étellel-itallal és persze rengeteg pizzával. A kedves ünnepély Nápolyt éltető hagyományát 2023-ban hoztuk el először
                    Magyarországra, a Nápolyi Pizzériák Éjszakája nevű országos pizza-fieszta segítségével.
                </Text>
            </VStack>
        </Box>
    );
};

export default WhatIsThis;
