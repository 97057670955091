import React from 'react';
import { Box, Container, Text, Link, HStack, useTheme } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  return (
    <Box as="footer" bg={theme.colors.footer} py={4}>
      <Container maxW="container.xl">
        <HStack justifyContent="center" spacing={4}>
          <Link as={RouterLink} to="/cookie-policy" color="white">Cookie Policy</Link>
          <Link as={RouterLink} to="/terms-and-conditions" color="white">Terms and Conditions</Link>
          {/* Add more policy links here as needed */}
        </HStack>
        <Text textAlign="center" mt={2} color="white">
          © {new Date().getFullYear()} Napiej.hu. All rights reserved.
        </Text>
      </Container>
    </Box>
  );
};

export default Footer;
